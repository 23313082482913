@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {        
    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .horizon-center {
        margin: 0;
        position: absolute;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .absolute-center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }    
    
}

:root {
    --Fansi_BG: #282828;
    --Fansi_Dark_BG: #090909;
    --salmon: #e5ac6e;
    --gen2blue: #5fddff;
    --bgNFT: #0f1e23;
    --djPink: #ff52b9;
    --lightgray: #050404;
    --mbYellow: #ffc76e;
    --mbRed: #ff554d;
  }

body {
    background-color: black;
    color: white;
    margin: 0;
    font-family: 'Noto Sans TC';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh; 
    min-width: 375px; 
}

.rounded {
    border-radius: 0.25rem;
}

.section-sm {
    @apply mx-auto;
    width: 100%;
    max-width: 880px;
}

.section-md {
    @apply mx-auto;
    width: 100%;
    max-width: 1120px;
}

.section {
    @apply mx-auto;
    width: 100%;
    max-width: 1280px;
}

.section-lg {
    @apply mx-auto;
    width: 100%;
    max-width: 1440px;
}

.btn {
    position: relative;
    min-height: 32px;
    border-radius: 6px;
    transition: all 0.2s ease-out;
    border: solid 1px #5fddff;
    background-clip: padding-box;
    /* !important */
    background-color: rgba(95, 221, 255, 0.14);
}

.btn-disabled {
    color: #575757;
    background-color: rgba(51, 51, 51, 0.4);
    pointer-events: none;
    border-radius: 24px;
    border: solid 1px #575757;
}

.btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -2px;
    /* !important */
    border-radius: inherit;
    /* !important */
}

.btn:hover {
    background-color: #000000;
}

.btn-rainbow {
    color:black;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    @apply bg-gen2blue;
}

.btn-rainbow:hover {
    background-color: white;
}

.btn-disabled.btn-rainbow {
    background: #bbb;
    color: #d8d8d8;
}

.tab-nav-container.fixed {
    @apply my-0 px-4;
}

.tab-nav-container:before {
    /*@apply bg-white absolute inset-y-0 right-full w-screen block z-10;*/
    @apply absolute inset-y-0 right-full w-screen block z-10;
    content: "";
}

.tab-nav-container:after {
    /*@apply bg-white absolute inset-y-0 left-full w-screen block z-10;*/
    @apply absolute inset-y-0 left-full w-screen block z-10;
    content: "";
}

.tab-nav {
    @apply inline-block relative text-center no-underline;
    color: #fff;
    line-height: 36px;
}

.tab-nav-active {
    border-bottom: 1px solid var(--gen2blue);
    color: var(--gen2blue);
    font-weight: bold;
}

.tab-nav-active:after {
    @apply absolute block inset-0 top-auto;
    content: "";
    /*background-color: var(--FANSI_dark_gray);*/
    background-color: var(--gen2blue);
    height: 2px;
}

.fansi_bgColor {
    background-color: var(--Fansi_BG);
  }

.fansi_dark_bg {
    background-color: var(--Fansi_Dark_BG);
}

.salmon {
    color: var(--salmon);
}

.djPink {
    color: var(--djPink);
}

.gen2blue {
    color: var(--gen2blue);
}

.mbYellow {
    color: var(--mbYellow)
}

.mbRed {
    color: var(--mbRed)
}

.bgNFT{
    background-color:var(--bgNFT);
}

.boderNFT:hover{
    border-radius: 8px;
    border: solid 1px #089dc6;
}

.MetaBoom {
    flex-grow: 0;
    text-shadow: 2px 0 0 rgba(255, 82, 185, 0.6), -3px 0 0 rgba(8, 157, 198, 0.6),
      0 4px 4px rgba(0, 0, 0, 0.25);
}

.sharePlayList {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.lineClamp2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.Roboto {
    font-family: "Roboto", sans-serif;
}

.NotoSans {
    font-family: "Noto Sans TC", sans-serif;
}

.OpenSans {
    font-family: "Open Sans", sans-serif;
}

.RussoOne {
    font-family: "Russo One", sans-serif;
}

.MetaBoom {
    flex-grow: 0;
    text-shadow: 2px 0 0 rgba(255, 82, 185, 0.6), -3px 0 0 rgba(8, 157, 198, 0.6),
        0 4px 4px rgba(0, 0, 0, 0.25);
}

.border {
    border-width: 1px;
}

.pb100 {
    height: 0px;
    padding-bottom: 100%;
}

.htBtn {
    background: radial-gradient(116.1% 143.81% at 0% 15.18%, #FFF1A9 0%, #00E4D7 41.15%, #FF554D 90.16%)
}

.htBlock {
    background: linear-gradient(100.23deg, #BC6786 1.85%, #205F7A 15.71%, #1F4A59 59.85%, #072E36 84.34%);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideSBar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideSBar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.hr {
    width: 100%;
    height: 1px;
}
