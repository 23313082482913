input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  margin-right: 10px;
  border-radius: 6px;
  outline: 0;
  background: #ccc;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative; /* 設為相對位置，為了前後區塊的絕對位置而設定 */
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #5fddff;
  border-radius: 50%;
  transition: 0.2s; /* 點選放大時候的漸變時間 */
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb:before,
input[type='range']::-webkit-slider-thumb:after {
  position: absolute;
  top: 3px;
  width: 2000px; /* 長度很長沒關係，因為剛剛有用 overflow:hidden 了 */
  height: 4px;
  content: '';
  pointer-events: none; /* 讓滑鼠可以點擊穿透偽元素，不然會點不到下面 */
  transition: 0.2s;
  cursor: pointer;
}

input[type='range']:active::-webkit-slider-thumb {
  width: 18px;
  height: 18px;
  border: 3px solid #5fddff;;
}

/*scroll bar */
::-webkit-scrollbar {
  width: 1px;
} /* this targets the default scrollbar (compulsory) */

::-webkit-scrollbar-track {
  background-color: #090909;
} /* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #bdbdbd;
} /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: #bdbdbd;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: black;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */


