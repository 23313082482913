.fade-in-image {
    animation-fill-mode: forwards;
    animation: fadeIn 3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-out-image {
    animation-fill-mode: forwards;
    animation: fadeOut 3s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}