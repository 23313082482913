/* Menu animation */
.hamburger {
    position: relative;
}

.hamburger .line {
    width: 28px;
    height: 2px;
    background-color: #fff;
    display: block;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
}

.hamburger:hover {
    cursor: pointer;
}

/* ONE */
.hamburger.is-active .line:nth-child(2) {
    opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
    background-color: #5fddff;
}

.hamburger.is-active .line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
    background-color: #5fddff;
}

/* Menu animation */